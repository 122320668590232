@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Caveat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rokkitt:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Special+Elite&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



